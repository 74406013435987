import { patchState, signalStore, withMethods, withState } from '@ngrx/signals';

export const enum IMAGE_CAPTURE {
  FRONT = 'front',
  BACK = 'back',
}

export type UploadWizardState = {
  step: IMAGE_CAPTURE;
  started: boolean;
};

export const initialUploadWizardState: UploadWizardState = {
  step: IMAGE_CAPTURE.FRONT,
  started: false,
};

export const UploadWizardStore = signalStore(
  { providedIn: 'root' },
  withState(initialUploadWizardState),
  withMethods((state) => ({
    startCapture(): void {
      patchState(state, { started: true });
    },
    toBackStep(): void {
      patchState(state, { step: IMAGE_CAPTURE.BACK, started: false });
    },
    resetWizard(): void {
      patchState(state, initialUploadWizardState);
    },
  })),
);
