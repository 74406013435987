import { createActionGroup, createFeature, createReducer, emptyProps } from '@ngrx/store';

export const UploadUiActions = createActionGroup({
  source: 'Upload UI',
  events: {
    navigateToScanningIds: emptyProps(),
    navigateToScanningInsurance: emptyProps(),
    navigateBack: emptyProps(),
    navigateHome: emptyProps(),
  },
});

export const uploadFeature = createFeature({
  name: 'upload',
  reducer: createReducer({}),
});

export const { name: SCANNING_STATE_FEATURE_KEY, reducer: scanningReducer } = uploadFeature;
