import { computed } from '@angular/core';

import { patchState, signalStore, withComputed, withMethods, withState } from '@ngrx/signals';
import { OutputFileEntry } from '@uploadcare/file-uploader';

import { UploadSecureSignature } from '@abbadox-monorepo/core-utils';

export type UploadFile = OutputFileEntry<'success'>;

export type FilesState = {
  front: UploadFile | null;
  back: UploadFile | null;
  secureParams: UploadSecureSignature;
  securePreviewUrl: string;
};

export const initialFilesState: FilesState = {
  front: null,
  back: null,
  secureParams: {
    secureSignature: '',
    secureExpire: '',
  },
  securePreviewUrl: '',
};

export const FilesStore = signalStore(
  { providedIn: 'root' },
  withState(initialFilesState),
  withComputed(({ front, back, secureParams }) => {
    const hasFront = computed(() => Boolean(front()?.name));
    const hasBack = computed(() => Boolean(back()?.name));
    const hasImages = computed(() => hasFront() && hasBack());
    const frontPreviewUrl = computed(
      () =>
        `${front()?.cdnUrl}-/preview/-/resize/600x/-/autorotate/no/?token=exp=${secureParams().secureExpire}~hmac=${secureParams().secureSignature}`,
    );
    const backPreviewUrl = computed(
      () =>
        `${back()?.cdnUrl}-/preview/-/resize/600x/-/autorotate/no/?token=exp=${secureParams().secureExpire}~hmac=${secureParams().secureSignature}`,
    );

    return { hasFront, hasBack, hasImages, frontPreviewUrl, backPreviewUrl };
  }),
  withMethods((state) => ({
    addFrontFile(file: UploadFile): void {
      patchState(state, { front: file });
    },
    addBackFile(file: UploadFile): void {
      patchState(state, { back: file });
    },
    setSecureParams(secureParams: UploadSecureSignature): void {
      patchState(state, { secureParams });
    },
    resetState(): void {
      patchState(state, initialFilesState);
    },
  })),
);
