import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';

import { Store } from '@ngrx/store';
import { of } from 'rxjs';

import { UploadUiActions } from '../upload.state';

/** Resets the state of the workflow anytime the home page is navigated to. */
export const uploadResolver: ResolveFn<boolean> = () => {
  const store = inject(Store);

  store.dispatch(UploadUiActions.navigateHome());

  return of(true);
};
